import axios from "axios"
import { aiFunctions, aiLink } from "../../const"

export const getNewsSummary = async(city)=>{
    let info = await axios.get(`${aiLink+aiFunctions[0]}${city}`).then(res=>res.data).catch(e=>false)
    console.log("info",info)
    return info
}
export const getImprovedNewsSummary = async(city)=>{
    let info = await axios.get(`${aiLink+aiFunctions[0]}${city}`).then(res=>res.data).catch(e=>false)
    console.log("info",info)
    return info
}
export const getNewsSummaryPerCategory = async(category)=>{
    let info = await axios.get(`${aiLink+aiFunctions[1]}${category}`).then(res=>res.data).catch(e=>false)
    console.log("info",info)
    return info
}

export const getTodayNewsSummary = async()=>{
    let info = await axios.get(`${aiLink+aiFunctions[2]}`).then(res=>res.data).
catch(e=>false)
    console.log("info",info)
    return info
}