import React, { useState, useEffect } from 'react';
import './testcase.scss';
import { listofCities } from '../const';
import { getImprovedNewsSummary } from './Services/getnew_summary';

export const TestCaseTwo = () => {
    const [news, setNews] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedCity, setSelectedCity] = useState(listofCities[0]);
    const [resources,setResources] =useState([])
    const fetchData = async () => {
        setLoading(true);
        try {
            const info = await getImprovedNewsSummary(selectedCity);
            if (Array.isArray(info.summary) && info.summary.length > 0) {
                const reslist = []
                info.news.forEach(element => {
                    reslist.push(element)
                });
                setResources(reslist)
                setNews(info.summary);
            } else {
                setNews(["No Updates available"]);
            }
            setError(false);
        } catch (error) {
            console.error(error);
            setError(true);
            setNews(["Failed to fetch news."]); // Ensure news is always an array
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!selectedCity) {
            setLoading(false);
            setNews(["Please Select a city!"]);
            return;
        }
        fetchData();
    }, [selectedCity]);

    return (
        <div>
            <div className="news">
                <div className="flex">
                    {listofCities.sort().map((city) => (
                        <button
                            key={city}
                            className="btn-primary"
                            style={{ backgroundColor: selectedCity === city ? '#0A2D42' : '#02C1F5' }}
                            onClick={() => setSelectedCity(city)}
                        >
                            {city}
                        </button>
                    ))}
                </div>
                <h1>Latest News From {selectedCity}</h1>
                {error && <h3>Error loading news. Please wait or try again.</h3>}
                {loading ? (
                    <div className="spinner"></div>
                ) : (
                    <>
                    <ul className='new_summary list'>
                        {news.map((e, index) => (
                            <li key={index} style={{ whiteSpace: 'pre-wrap' }}>{e}</li>
                        ))}
                    </ul>
                    <h2>Resources:</h2>
                    <ul className='resources_list'>
                        {resources.map(e=>{
                           return <li><a href={e.url} target='_blank'>{e.name}</a></li>
                        })}
                    </ul>
                    </>
                )}
            </div>
        </div>
    );
};
