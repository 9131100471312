import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/entryanimation.scss'; // Import your CSS file with animation styles
import logo from "../images/logo.png";

export const EntryAnimation = () => {
  return (
    <div className='w-100'>
    <div className='EntryPoint'>
        <div className='bg'></div>
        <img src={logo} alt="Logo" className="logo"/>
        <h1 className='h1 text-white'>PaperPass</h1>
    </div>
    </div>
  );
};

