import "./styles/test_list.scss";
import './scss/utils.scss'
import { collection, onSnapshot } from 'firebase/firestore';
import db from '../firebase';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LogoComponent } from "../Utils/logo";
import { Footer } from "../Utils/footer";

export const TestList = () => {
    const [testCases, setTestCases] = useState([]);
    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'test_cases'), (snapshot) => {
            const cases = snapshot.docs.map(doc => doc.data()
            );
            setTestCases(cases);
        });

        // Cleanup the subscription when the component unmounts
        return () => unsubscribe();
    }, []); // Empty dependency array to ensure the effect runs only once when the component mounts

    const TestCases = () => {
        return testCases.map(test => {  console.log(test.formId)         
            return (
            <Link to={`/test/${test.formId}`} style={{ textDecoration: 'none' }}>
            <div key={test.formId} className="card" >
                <h2 style={{padding:'4rem'}}>{test.title}</h2>
            </div>
            </Link>
            )
        });
    };

    return (
        <div className="test_list">
            <LogoComponent/>
            <div className="test_cases_holder">
                <h1>Tests</h1>
                <div className="row justify-center align-center">
                    <TestCases />
                </div>
            </div>
          <Footer/>
        </div>
    );
};
