import React, { useState, useEffect } from 'react';
import './testcase.scss';
import { listofCities, newsCategories } from '../const';
import { getNewsSummary, getNewsSummaryPerCategory, getTodayNewsSummary } from './Services/getnew_summary';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const TestCaseOne = () => {
    const [news, setNews] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedCity, setSelectedCity] = useState();
    const [selectedCategory, setSelectedCategory] = useState();
    const [todaysNews, setTodaysNews] = useState("Heutige News");

    const fetchData = async () => {
        setLoading(true);
        try {
            let info;
            if (selectedCity) {
                info = await getNewsSummary(selectedCity);
            } else if (selectedCategory) {
                info = await getNewsSummaryPerCategory(selectedCategory);
            }else if (todaysNews){
                info = await getTodayNewsSummary();

            }

            if (info && typeof info.summary === "string") {
                setNews(info.summary);
            } else {
                setNews("No Updates available");
            }
            setError(false);
        } catch (error) {
            setError(true);
            setNews("Failed to fetch news.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!selectedCity && !selectedCategory && !todaysNews) {
            setLoading(false);
            setNews("Please Select a city or category!");
            return;
        }
        fetchData();
    }, [selectedCategory,selectedCity,todaysNews]);

    return (
        <div>
            <div className="news">
                <div className="flex">
                <a href='#summary-text'>
                    <button
                        className="btn-primary"
                        style={{ backgroundColor: todaysNews === "Heutige News" ? '#0A2D42' :
                            '#02C1F5' }}
                        onClick={() => {
                            setSelectedCategory(null);
                            setSelectedCity(null);
                            setTodaysNews("Heutige News")
                        }}
                        >
                        Heutige News
                        </button>
                    </a>
                    {listofCities.sort().map((city) => (
                        <a href='#summary-text'>
                        <button
                            key={city}
                            className="btn-primary"
                            style={{ backgroundColor: selectedCity === city ? '#0A2D42' : '#02C1F5' }}
                            onClick={() => {
                                setSelectedCategory(null);
                                setSelectedCity(city);
                                setTodaysNews(null)
                            }}
                        >
                            {city}
                        </button>
                        </a>
                    ))}
                </div>
                <div className="flex mx-auto">
                    {newsCategories.sort().map((category) => (
                        <a href='#summary-text'>
                        <button
                            key={category}
                            className="btn-primary"
                            style={{ backgroundColor: selectedCategory === category ? '#0A2D42' : '#02C1F5' }}
                            onClick={() => {
                                setSelectedCity(null);
                                setSelectedCategory(category);
                                setTodaysNews(null)
                            }}
                        >
                            {category}
                        </button>
                        </a>
                    ))}
                </div>
                <div id="summary-text">
                {error && <h3>This will take something. Please wait...</h3>}
                {loading ? (
                    <div className="loading-text">Wir holen die wichtigsten News für dich ein...</div>
                ) : (
                    <div id="summary-text">
                        <Markdown className="new_summary" remarkPlugins={[remarkGfm]}>{news}</Markdown>
                    </div>
                )}
                 </div>
            </div>
        </div>
    );
};
