import { Link, useParams } from "react-router-dom";
import "../Components/styles/test_screen.scss";
import "../Utils/logo.scss";
import { collection, onSnapshot } from 'firebase/firestore';
import db from '../firebase';
import { useEffect, useState } from "react";
import './testcase.css'
import { LogoComponent } from "../Utils/logo";
import { Footer } from "../Utils/footer";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../Utils/news.scss'
import { TestCaseOne } from "../TestCases/test_case_one";
import { TestCaseTwo } from "../TestCases/test_case_two";
export const TestCaseScreen = () => {
    const params = useParams();
    // console.log(JSON.stringify(params.id)
    // setCities(listofCities)
    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'htmlSamples'), (sn) => {
            const info = sn.docs.find(doc => doc.id === params.id);
            if (info) {
                // Extract the data from the document
            } else {
                // Handle case when document is not found
                // console.log(`Document with id ${params.id} not found.`);
            }
        });
    
        // Call the fetchData function
    
        // Cleanup the subscription when the component unmounts
        return () => unsubscribe();
    },[]); // Depend on params.id to re-run the effect when the ID changes
    return (
        <div className="test_screen">
            <LogoComponent/>
            <div>
                {
                   params.id=='1'&&(
                    <TestCaseOne/>
                )}
                  {
                   params.id=='2'&&(
                    <TestCaseTwo/>
                )}
            </div>
            <div className="submit-section">
               <Link to='/'><button className="btn-primary"><FontAwesomeIcon icon={faArrowLeft} className="arr-left"/>Back</button></Link> 
               <Link to={`/test/submit/${params.id}`}><button className="btn-primary">Feedback <FontAwesomeIcon icon={faArrowRight}  className="arr-right"/></button></Link>
            </div>
            <Footer/>
        </div>
    );
};
