import logo from '../images/logo.png';
import './logo.scss'
export const LogoComponent = ()=>{
    return (
        <div className='logo-co'>
         <div className="logoblock">
            <img src={logo} alt="Logo" />
        </div>
        <div className="heading-holder mx-auto text-center my-3">
            <h1>Paper Pass Update 2.0</h1>
            <a href="https://chat.whatsapp.com/K5lGwhcDOjUBBdyW2MFVri">Teste unser neues KI-Update und sag uns, wie es dir gefällt!</a>
        </div>
        </div>
    )
}