import '../Components/scss/utils.scss';
import { LogoComponent } from '../Utils/logo';
import { Footer } from '../Utils/footer';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes,faUsers} from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';
import { doc, setDoc, Timestamp } from "firebase/firestore"; 
import { collection, addDoc, query, where, getDocs  } from 'firebase/firestore';
import db from '../firebase'
import './form.scss'
export const FormPage = () => {
 
  const params = useParams()
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, serIsError] = useState(false)
  const [question,setQuestion] = useState([])
  let sub = false;
  let userinfo ={
    firstname:'',
    lastname:'',
    email:''
  }
  const getData = async ()=>{
    const q = query(collection(db, "test_cases"), 
    where("formId", "==", params.id));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      setQuestion(doc.data().questions)
    });
    
  }
  useState(async()=>{
    await getData()
  },[])
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent form submission from refreshing the page
    let feedback= {
      questions: question,
      userInfo: userinfo
    }
    const docData = {
      formId:params.id,
      ...feedback,
      date:Timestamp.fromDate(new Date("December 10, 1815")),
    }
    await setDoc(doc(db, "test_cases",params.id), docData)
    .then(async () => {
      // Add a new subcollection inside the feedback document
      const feedbackDocRef = doc(db, "test_cases",params.id);
      const newSubcollectionRef = collection(feedbackDocRef, "feedbacks");
      await addDoc(newSubcollectionRef, docData).then(()=>{
        setIsSubmitted(true)
        serIsError(false)
      });
    })
    .catch((error) => {
      serIsError(true)
    });

    if(sub){
      await addDoc(collection(db,'test_subscriptions'), userinfo).then(()=>{
      })
    .catch((error) => {
    });
  }
   }
  const InputField = ({ type, label, placeholder }) => {
    const handleInputChange = (e) => {
      const inputValue = e.target.value;
      // Determine which state variable to update based on the label
      switch(label) {
        case 'Vorname':
          userinfo.firstname = inputValue
          // setFirstName(inputValue);
          break;
        case 'Nachname':
          userinfo.lastname =inputValue
          // setLastName(inputValue);
          break;
        case 'Email Address':
          userinfo.email=inputValue
          // setEmail(inputValue);
          break;
        default:
          break;
      }
    };
    return (
      <div>
        <label>{label}</label>
        <br/>
        <input
          type={type}
          placeholder={placeholder}
          required={true}
          onChange={handleInputChange}
        />
      </div>
    );
  };

  const TextArea = ({ label, placeholder,index }) => {
    // console.log(index)
    return (
      <div>
        <label>{label}</label>
        <br />
        <textarea
          rows={3}
          placeholder={placeholder}
          required={true}
          onChange={(e) => {
           question[index] = {
            question:label,
            answer:e.target.value
           }
          }}
        ></textarea>
      </div>
    );
  };

  return (
    <div className="container align-center">
      <LogoComponent />
      <form className="form" onSubmit={handleSubmit}>
        <InputField type="text" label="Vorname" placeholder="" />
        <InputField type="text" label="Nachname" placeholder="" />
        <InputField type="email" label="Email Address" placeholder="" />
        {question.map((e,index)=>{
          // console.log('index',index)
          return (
            <TextArea type='text' label={e.question} index={index}/>
          )
        })}
        <br />
        <br />
        <div className='w-100 mx-auto'>
        <a className='whatsapp_link' href='https://chat.whatsapp.com/K5lGwhcDOjUBBdyW2MFVri'><FontAwesomeIcon className='fa' icon={faUsers} />Click to Join our WhatsApp Group </a>
          <button className="btn-primary w-70 mx-auto my-5">Submit</button>
        </div>
      </form>
      {isSubmitted && (
        <div id='submitted' className='modal success_modal'>
          <button className='close' onClick={() => setIsSubmitted(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <div className='thankyou'>
          <h2 className='jumping_text'>Thank you!</h2>
          </div>
          <p>Your feedback means a lot to us!</p>
          <Link to='/test/'><button className='btn-primary'>Back to all tests</button></Link>
        </div>
      )}{
        isError &&(
          <div><h1>Not able to submit, please try again!</h1></div>
        )
      }
      <Footer />
    </div>
  );
}
