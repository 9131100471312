import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HomePage } from './Pages/homePage';
import { TestCaseScreen } from './Pages/testcase';
import { FormPage } from './Pages/form';
const App = () => {
  useEffect(()=>{
  })
  return (
    <Router>
      <Routes>
      {/* <Route path="/test" element={<EntryAnimation/>} /> */}
      <Route path="/" element={<HomePage/>} />
      <Route path="/test/:id" element={<TestCaseScreen/>} />
      <Route path="/test/submit/:id" element={<FormPage/>} />
      {/* <Route path="/test/submitted" element={<FormPage/>} /> */}
      </Routes>
    </Router>
  );
};

export default App;
